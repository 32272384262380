import React from "react";

import Link from "../components/link";
import Layout from "../components/layout";
import SEO from "../components/seo";

const SubscribedPage = () => (
  <Layout>
    <SEO title="Subscribed" keywords={[]} />

    <div className="p-4 pt-0 max-w-sm m-auto">
      <h2>You are subscribed!</h2>
      <p className="text-center">
        We confirmed your email address, so we're good to go.{" "}
      </p>

      <p>
        If you can't wait for some Electron content, check out the{" "}
        <Link
          to="https://electronjs.org/community"
          className="text-electron hover:text-teal-darker"
        >
          Electronjs.org community page
        </Link>
        . Scroll down for a list of tools, boilerplates, videos and more.
      </p>
    </div>
  </Layout>
);

export default SubscribedPage;
